<template>
    <div>
        <div class="row pb-5" v-if="dataExist">
            <div class="col-lg-12">
                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden py-4 px-2">
                    <div class="row">
                        <div class="col-lg-6 mb-4">
                            <div class="product-slider-4">
                            <img class="img-fluid mx-auto d-block" style="width: 85%;" :src="course.banner ?  course.banner : 'https://via.placeholder.com/500.png'">
                            </div> 
                        </div>
                        <div class="col-lg-5 ml-1 offset-lg-1 col-md-12 pad-top-lg-200 pad-bottom-lg-100 pad-top-100 pad-bottom-75 pl-md--5">
                            <h4 class="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">{{ course.category }} - {{ course.level }}</h4>
                            <h3 class="fw-700 text-grey-900 display1-size lh-3 porduct-title display2-md-size display1-sm-size mt-1"> {{course.name}}</h3>
                            <div class="clearfix"></div>
                            <p class="font-xsss fw-600 text-grey-600 lh-30 pr-lg--5 mt-2 mr-5 ml-1">Instructor : {{course.created_by}}</p>
                            <div class="row my-3">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-signal mr-2"></i>{{course.category}} {{course.level}}</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-copy mr-2"></i>{{course.total_material}} Materials</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-puzzle-piece mr-2"></i>{{course.total_assignment}} Assignments</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-user-graduate mr-2"></i>{{course.total_student}} Students</div>
                            </div>
                            <div class="row" v-if="course.has_special">
                                <div class="col-12">
                                    <div class="btn btn-outline-success font-xss fw-500" :class="{'active': with_special}" @click="setSpecial">
                                        <div class="form-check">
                                            <input type="checkbox" v-model="with_special" class="form-check-input" id="checkSpecial">
                                            Buy {{with_special ? 'with' : 'without'}} Special Features
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <small class="text-success fw-500 font-xsss"><i class="fas fa-info-circle"></i> Purchase with Special Feature for Unlimited Access and Tutor!</small>
                                </div>
                            </div>
                            <div class="row" v-if="!isOwned">
                                <div class="form-group col-12">
                                    <select class="form-control" v-model="cycle" @change="changePrice">
                                        <option v-for="(item, index) in prices" :key="index" :value="item.value" :disabled="item.disabled">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="form-group col-12">
                                    <select class="form-control">
                                        <option value="" disabled selected>Owned All Cycles</option>
                                    </select>
                                </div>
                            </div>
                            <h6 class="font-xxl fw-700 text-current ls-2 mb-1">Rp.{{ course.price | numFormat('0,0') }}</h6>
                            <p class="font-xss fw-700 text-black ls-2" v-if="point.id && point.status == 1 && point.type == 'PERCENT'">Cashback Point : <span class="text-info">{{point.amount_point + '%'}}</span></p>
                            <p class="font-xss fw-700 text-black ls-2" v-if="point.id && point.status == 1 && point.type == 'AMOUNT'">Cashback Point : <span class="text-info">{{point.amount_point | numFormat('0,0')}}</span></p>
                            <div class="row mt-lg-4">
                                <div class="col-12">
                                    <a href="javascript:void(0)" class="btn btn-success btn-sm fw-600 mr-2"><i class="fas fa-wallet mr-2"></i>Wallet : Rp. {{wallet.balance == 0 ? 0 : wallet.balance | numFormat('0,0')}}</a>
                                    <a href="javascript:void(0)" class="btn btn-info btn-sm fw-600"><i class="fas fa-certificate mr-2"></i>Point : {{wallet.balance_point | numFormat('0,0')}}</a>
                                </div>
                            </div>
                            <div action="#" class="form-action my-4">
                                <div v-if="isOwned" class="product-action flex-row align-items-center">
                                    <button class="w-100 border-0 add-to-cart bg-current text-white pl-lg-5 pr-lg-5 fw-700 text-uppercase font-xssss float-left rounded-lg border-size-md d-inline-block mt-0 p-3 text-center ls-3" disabled>Owned</button>
                                </div>
                                <div v-else class="product-action flex-row align-items-center">
                                    <button v-if="!course.locked" @click="payment()" class="w-100 border-0 add-to-cart bg-current text-white pl-lg-5 pr-lg-5 fw-700 text-uppercase font-xssss float-left rounded-lg border-size-md d-inline-block mt-0 p-3 text-center ls-3">Get now</button>
                                    <button v-if="course.locked" @click="payment()" class="w-100 border-0 add-to-cart bg-current text-white pl-lg-5 pr-lg-5 fw-700 text-uppercase font-xssss float-left rounded-lg border-size-md d-inline-block mt-0 p-3 text-center ls-3" disabled>Coming Soon!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-4">
                    <div class="col-lg-12">
                        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                            <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-between product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                                <li class="active list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">Description</a></li>
                                <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">Objective</a></li>
                                <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs2" data-toggle="tab">Learning Path</a></li>
                            </ul>
                        </div>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="navtabs0">
                                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                    <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Description</b></h2></div>
                                    <div class="card-body pb-0">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div>
                                                    <p class="editable-text font-xssss fw-600 lh-28 text-grey-500 pl-0">{{course.description}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="navtabs1">
                                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                    <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Objective</b></h2></div>
                                    <div class="card-body pb-0">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div>
                                                    <div class="editable-text font-xssss fw-600 lh-28 text-grey-500 pl-0" v-html="course.objective"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="navtabs2">
                                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                    <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Learning Path</b></h2></div>
                                    <div class="card-body pb-0">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div>
                                                    <div id="accordion" class="accordion mb-0" v-if="cycle == 'All'">
                                                        <div class="card shadow-xss mb-0" v-for="(syll, i) in course.syllabus" :key="i">
                                                            <div class="card-header bg-greylight theme-dark-bg" :id="'heading'+i">
                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link " data-toggle="collapse" :data-target="'#collapse'+i" aria-expanded="false" :aria-controls="'collapse'+i"> {{syll.content}} </button></h5>
                                                            </div>
                                                            <div :id="'collapse'+i" class="collapse p-3 show" :aria-labelledby="'heading'+i" data-parent="#accordion">
                                                                <div class="card-body d-flex p-2" v-for="(point, j) in syll.point_syllabus" :key="j">
                                                                    <span class="bg-current px-2 rounded-lg font-xssss text-white fw-600">Cycle {{point.cycle}}</span>
                                                                    <span class="font-xssss fw-500 text-grey-500 ml-2">{{point.content}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="accordion" class="accordion mb-0" v-else>
                                                        <div class="card shadow-xss mb-0" v-for="(syll, i) in course.syllabus" :key="i">
                                                            <div class="card-header bg-greylight theme-dark-bg" :id="'heading'+i">
                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link " data-toggle="collapse" :data-target="'#collapse'+i" aria-expanded="false" :aria-controls="'collapse'+i"> {{syll.content}} </button></h5>
                                                            </div>
                                                            <div :id="'collapse'+i" class="collapse p-3 show" :aria-labelledby="'heading'+i" data-parent="#accordion">
                                                                <div class="card-body d-flex p-2" v-for="(point, j) in syll.point_syllabus.filter(x => x.cycle == cycle)" :key="j">
                                                                    <span class="bg-current px-2 rounded-lg font-xssss text-white fw-600">Cycle {{point.cycle}}</span>
                                                                    <span class="font-xssss fw-500 text-grey-500 ml-2">{{point.content}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                <div class="my-3" v-show="loadData">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
            </div>
        </div>
        <modal name="payment-modal"
            :adaptive="true"
            :height="'auto'"
            :clickToClose="false"
            >
            <div class="container-fluid p-0">
                <div class="card">
                    <div class="card-header py-3 bg-white border-0">
                        <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                        <h1 class="fredoka-font ls-3 fw-700 text-current-eling font-lg mb-0">Elingway</h1>
                    </div>
                    <div class="card-body py-3">
                        <div class="row">
                            <div class="col-12">
                                <table>
                                    <tr>
                                        <td class="pr-2"><span class="font-xs text-black fw-600 mb-0">{{course.name}}<br><span class="text-muted font-xsss">Cycle : {{ cycle_name }}</span></span></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr v-if="point.id && point.status == 1">
                                        <td><span class="font-xsss text-black fw-500 mb-0">Cashback Point</span></td>
                                        <td class="px-2">:</td>
                                        <td><span class="font-xsss text-info fw-600 mb-0" v-if="point.type == 'PERCENT'"><i class="fas fa-certificate mr-2"></i>{{point.amount_point + '%'}}</span></td>
                                        <td><span class="font-xsss text-info fw-600 mb-0" v-if="point.type == 'AMOUNT'"><i class="fas fa-certificate mr-2"></i>{{point.amount_point | numFormat('0,0')}}</span></td>
                                    </tr>
                                </table>
                                <p class="font-xl text-current fw-700 my-0 text-right">Rp. {{course.price | numFormat('0,0')}}</p>
                                <hr>
                                <div class="row">
                                    <div class="col-6">
                                        <a href="#" class="text-success fw-600"><i class="fas fa-wallet mr-2"></i>Wallet : Rp. {{wallet.balance == 0 ? 0 : wallet.balance | numFormat('0,0')}}</a>
                                    </div>
                                    <div class="col-6 text-right">
                                        <a href="#" class="text-info fw-600"><i class="fas fa-certificate mr-2"></i>Point : {{wallet.balance_point | numFormat('0,0')}}</a>
                                    </div>
                                    <div class="col-12 mb-2" v-if="step == 1">
                                        <div class="form-check">
                                            <input v-model="withPoint" class="form-check-input" type="checkbox" :disabled="step == 2">
                                            <a href="javascript:void(0)" v-if="step == 1" @click="withPoint = !withPoint" class="form-check-label font-xsss text-grey-600" for="check">Purchase with point</a>
                                            <a href="javascript:void(0)" v-else class="form-check-label font-xsss text-grey-600" for="check">Purchase with point</a>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-2" v-if="step == 1">
                                        <button @click="step = 2" class="btn btn-block btn-success fw-600" v-if="!withPoint" :class="{'disabled': (wallet.balance < course.price) || process == true}" :disabled="(wallet.balance < course.price) || process == true">
                                            <i class="fas fa-wallet mr-2"></i>
                                            <span v-if="process">Processing...</span>
                                            <span v-if="wallet.balance < course.price">Your Wallet not enough!</span>
                                            <span v-else>Purchase</span>
                                        </button>
                                        <button @click="step = 2" class="btn btn-block btn-info fw-600" v-else :class="{'disabled': wallet.balance_point < course.price}" :disabled="wallet.balance_point < course.price">
                                            <i class="fas fa-certificate mr-2"></i>
                                            <span v-if="process">Processing...</span>
                                            <span v-if="wallet.balance_point < course.price">Your Point not enough!</span>
                                            <span v-else>Purchase with Points</span>
                                        </button>
                                    </div>
                                    <div class="col-12 mb-2" v-if="step == 2">
                                        <div class="row">
                                            <div class="col-12 mb-1">
                                                <small class="fw-600 font-xssss text-danger">Note : Make sure your purchase is appropriate!</small>
                                            </div>
                                            <div class="col-6">
                                                <button @click="step = 1" class="btn btn-outline-danger fw-600"><i class="fas fa-times mr-2"></i>{{process ? 'Processing...' : 'Cancel'}}</button>
                                            </div>
                                            <div class="col-6 text-right">
                                                <button @click="purchase()" class="btn btn-info fw-600" :class="{'disabled': process == true}" :disabled="process == true"><i class="fas fa-check mr-2"></i>{{process ? 'Processing...' : 'Confirm'}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="wallet.balance < course.price" class="col-12 text-center">
                                        <span class="font-xss text-black fw-500">Click <a href="javascript:void(0)" @click="topup()" class="font-xss text-info fw-500">Top Up</a> if you want Top Up your wallet</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            course:{},
            point:{
                id: 0,
                amount_point: 0,
                course_id: 0,
                status: 0,
                type: "",
                used_limit: 0
            },
            media: process.env.VUE_APP_URL_CLOUD,
            snap_token: '',
            ori_price: 0,
            order_datetime: '',
            paramId: this.$route.params.idCourse,
            dataExist: false,
            with_special: false,
            dataNotExist: false,
            loadData: true,
            withPoint: false,
            cycle_name: 'All Cycles',
            cycle: 'All',
            isOwned: false,
            wallet: {
                id: 0,
                balance: 0,
                balance_point: 0,
                user_id: 0,
                created_at: null,
                updated_at: null
            },
            process: false,
            step: 1,
            prices: [
                {
                    name: 'All Cycles',
                    value: 'All',
                    price: 0
                }
            ]
        }
    },
    created(){
        this.getCourse();
        this.getWallet();
    },
    methods:{
        setSpecial() {
            this.with_special = !this.with_special
            this.prices= [
                {
                    name: 'All Cycles',
                    value: 'All',
                    price: 0
                }
            ]
            if(this.with_special) {
                if(!this.course.owned) {
                    this.prices[0].price = this.course.price_special
                    this.course.cycle.map((item) => {
                        let data = {
                            name: 'Special Cycle '+item.cycle,
                            value: item.cycle,
                            price: item.cycle == 1 ? this.course.price_special_cycle_1 : item.cycle == 2 ? this.course.price_special_cycle_2 : item.cycle == 3 ? this.course.price_special_cycle_3 : 0,
                            disabled: false,
                        }
                        this.prices.push(data)
                    })
                } else {
                    this.prices = []
                    let detail = this.course.owned_detail
                    this.course.cycle.map((item) => {
                        let disabled = item.cycle == 1 && detail.cycle_1_special == true ? true : item.cycle == 2 && detail.cycle_2_special == true ? true : item.cycle == 3 && detail.cycle_3_special == true ? true : false
                        let price = item.cycle == 1 ? this.course.price_special_cycle_1 : item.cycle == 2 ? this.course.price_special_cycle_2 : item.cycle == 3 ? this.course.price_special_cycle_3 : 0
                        if(!disabled) {
                            this.course.price = price
                            this.cycle = item.cycle
                            this.cycle_name = `Special Cycle ${item.cycle}`
                        }
                        let name = '';
                        if(disabled) {
                            name = `Special Cycle ${item.cycle} ${disabled ? '(Owned)' : ''}`
                        } else {
                            if(item.cycle == 1 && this.course.owned_detail.cycle_1) {
                                name = `Special Cycle ${item.cycle} (Upgrade)`
                                price = price > this.course.price_cycle_1 ? price - this.course.price_cycle_1 : price;
                            } else if(item.cycle == 2 && this.course.owned_detail.cycle_2) {
                                name = `Special Cycle ${item.cycle} (Upgrade)`
                                price = price > this.course.price_cycle_2 ? price - this.course.price_cycle_2 : price;
                            } else if(item.cycle == 3 && this.course.owned_detail.cycle_3) {
                                name = `Special Cycle ${item.cycle} (Upgrade)`
                                price = price > this.course.price_cycle_3 ? price - this.course.price_cycle_3 : price;
                            } else {
                                name = `Special Cycle ${item.cycle}`
                            }
                        }
                        let data = {
                            name: name,
                            value: item.cycle,
                            price: price,
                            disabled: disabled,
                        }
                        this.prices.push(data)
                    })
                }
                if(this.course.owned) {
                    let hasCycle1 = this.course.cycle.find(x => x.cycle == 1) != null
                    let hasCycle2 = this.course.cycle.find(x => x.cycle == 2) != null
                    let hasCycle3 = this.course.cycle.find(x => x.cycle == 3) != null
                    let owned = true
                    if(hasCycle1) {
                        owned = owned && this.course.owned_detail.cycle_1_special
                    }
                    if(hasCycle2) {
                        owned = owned && this.course.owned_detail.cycle_2_special
                    }
                    if(hasCycle3) {
                        owned = owned && this.course.owned_detail.cycle_3_special
                    }
                    this.isOwned = owned
                }
            } else {
                if(!this.course.owned) {
                    this.prices[0].price = this.ori_price
                    this.course.cycle.map((item) => {
                        let data = {
                            name: 'Cycle '+item.cycle,
                            value: item.cycle,
                            price: item.cycle == 1 ? this.course.price_cycle_1 : item.cycle == 2 ? this.course.price_cycle_2 : item.cycle == 3 ? this.course.price_cycle_3 : 0,
                            disabled: false,
                        }
                        this.prices.push(data)
                    })
                } else {
                    this.prices = []
                    let detail = this.course.owned_detail
                    this.course.cycle.map((item) => {
                        let disabled = item.cycle == 1 && detail.cycle_1 == true ? true : item.cycle == 2 && detail.cycle_2 == true ? true : item.cycle == 3 && detail.cycle_3 == true ? true : false
                        let price = item.cycle == 1 ? this.course.price_cycle_1 : item.cycle == 2 ? this.course.price_cycle_2 : item.cycle == 3 ? this.course.price_cycle_3 : 0
                        if(!disabled) {
                            this.course.price = price
                            this.cycle = item.cycle
                            this.cycle_name = `Cycle ${item.cycle}`
                        }
                        let data = {
                            name: `Cycle ${item.cycle} ${disabled ? '(Owned)' : ''}`,
                            value: item.cycle,
                            price: price,
                            disabled: disabled,
                        }
                        this.prices.push(data)
                    })
                }
                if(this.course.owned) {
                    let hasCycle1 = this.course.cycle.find(x => x.cycle == 1) != null
                    let hasCycle2 = this.course.cycle.find(x => x.cycle == 2) != null
                    let hasCycle3 = this.course.cycle.find(x => x.cycle == 3) != null
                    let owned = true
                    if(hasCycle1) {
                        owned = owned && this.course.owned_detail.cycle_1
                    }
                    if(hasCycle2) {
                        owned = owned && this.course.owned_detail.cycle_2
                    }
                    if(hasCycle3) {
                        owned = owned && this.course.owned_detail.cycle_3
                    }
                    this.isOwned = owned
                }
            }
            this.changePrice({target:{value: 'All'}})
            
        },
        changePrice(event) {
            let value = event.target.value
            let price = this.prices.find(x => x.value == value)
            if(price && price.price) {
                this.course.price = price.price
                this.cycle_name = price.name
                this.cycle = price.value
            } else {
                let price = this.prices.filter(x => x.disabled != true)[0]
                this.course.price = price.price
                this.cycle_name = price.name
                this.cycle = price.value
            }
        },
        async getCourse(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/course/detail?slug=` + this.paramId, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.length == 0) {
                    this.DataNotExist = true
                } else {
                    this.course = res.data
                    this.ori_price = this.course.price
                    if(!this.course.owned) {
                        this.prices[0].price = this.course.price
                        this.course.cycle.map((item) => {
                            let data = {
                                name: 'Cycle '+item.cycle,
                                value: item.cycle,
                                price: item.cycle == 1 ? this.course.price_cycle_1 : item.cycle == 2 ? this.course.price_cycle_2 : item.cycle == 3 ? this.course.price_cycle_3 : 0,
                                disabled: false,
                            }
                            this.prices.push(data)
                        })
                    } else {
                        this.prices = []
                        let detail = this.course.owned_detail
                        this.course.cycle.map((item) => {
                            let disabled = item.cycle == 1 && detail.cycle_1 == true ? true : item.cycle == 2 && detail.cycle_2 == true ? true : item.cycle == 3 && detail.cycle_3 == true ? true : false
                            let price = item.cycle == 1 ? this.course.price_cycle_1 : item.cycle == 2 ? this.course.price_cycle_2 : item.cycle == 3 ? this.course.price_cycle_3 : 0
                            if(!disabled) {
                                this.course.price = price
                                this.cycle = item.cycle
                                this.cycle_name = `Cycle ${item.cycle}`
                            }
                            let data = {
                                name: `Cycle ${item.cycle} ${disabled ? '(Owned)' : ''}`,
                                value: item.cycle,
                                price: price,
                                disabled: disabled,
                            }
                            this.prices.push(data)
                        })
                    }
                    this.getPoint(this.course.id)
                    if(this.course.owned) {
                        let hasCycle1 = this.course.cycle.find(x => x.cycle == 1) != null
                        let hasCycle2 = this.course.cycle.find(x => x.cycle == 2) != null
                        let hasCycle3 = this.course.cycle.find(x => x.cycle == 3) != null
                        let owned = true
                        if(hasCycle1) {
                            owned = owned && this.course.owned_detail.cycle_1
                        }
                        if(hasCycle2) {
                            owned = owned && this.course.owned_detail.cycle_2
                        }
                        if(hasCycle3) {
                            owned = owned && this.course.owned_detail.cycle_3
                        }
                        this.isOwned = owned
                    }
                    this.dataExist = true
                }
                this.loadData = false
            }).catch((err) => console.error(err.response))
        },
        async getWallet(){
            await axios.get(`${process.env.VUE_APP_URL_API}/account/balance`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.wallet = res.data.data
            }).catch((err) => console.error(err.response))
        },
        async getPoint(id){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/course/point?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.status == 200) {
                    this.point = res.data.data
                }
            }).catch((err) => console.error(err.response))
        },
        topup(){
            this.closeModal()
            this.$modal.show('topup-modal')
        },
        payment() {
            this.$modal.show('payment-modal')
        },
        async purchase() {
            this.process = true
            let data = {
                course_id: this.course.id,
                amount: this.course.price,
                cycle: this.cycle,
                with_special: this.with_special,
            }
            let type = this.withPoint ? 'POINT' : 'WALLET'
            await axios.post(`${process.env.VUE_APP_URL_API}/account/buy-course?type=${type}`,data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'Buy Course',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.step = 1
                    this.getCourse();
                    this.getWallet();
                    this.closeModal();
                }
                this.process = false
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Buy Course',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }  
                this.process = false
            })
        },
        closeModal(){
            this.$modal.hide('payment-modal')
        },
    },
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    ::v-deep .vm--modal {
        width: 90% !important;
        left: 5% !important;
    }
}
::v-deep .vm--modal {
    width: 600px;
}
</style>